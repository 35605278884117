/**
 * @ComponentFor FaqBlock
 */

import React from 'react';
import { epiPropertyValue, EpiProperty } from '@avensia/scope-episerver';
import FaqBlockType from './FaqBlock.type';
import { styled } from '@glitz/react';
import { delta } from 'Shared/Style';

type PropType = FaqBlockType;

const FaqBlock = (props: PropType) => (
  <div>
    <Header>{epiPropertyValue(props.header)}</Header>
    <EpiProperty for={props.text} />
  </div>
);

const Header = styled.div({
  fontWeight: 'bold',
  fontSize: delta,
});

export default FaqBlock;
